import React from 'react';
import { Badge as BSBadge } from 'react-bootstrap';
import cn from 'classnames';

const Badge = React.forwardRef((props, ref) => {
  const { className, children, ...rest } = props;

  const classes = cn('p-2 fw-semibold', className);

  return (
    <BSBadge pill className={classes} {...rest} ref={ref}>
      {children}
    </BSBadge>
  );
});

export default Badge;
