import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Accordion, Form, Image, Nav, Navbar } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BiBox } from 'react-icons/bi';
import { BsFiles, BsGear } from 'react-icons/bs';
import { CgFileDocument } from 'react-icons/cg';
import { HiChevronDoubleLeft } from 'react-icons/hi';
import { IoImageOutline, IoOpenOutline } from 'react-icons/io5';
import {
  MdOutlineAdminPanelSettings,
  MdOutlineSpaceDashboard,
} from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useFetchAccount, useFetchStore } from '../api/account';
import Logo from '../assets/images/mini-logo.png';
import { Routes } from '../routes';
import Badge from './Badge';
import Button from './Button';
import styles from './Sidebar.module.css';

const CollapsableNavItem = (props) => {
  const {
    eventKey,
    title,
    icon,
    children = null,
    link,
    style,
    emptyChild,
    isCollapsed = false,
    setShow,
  } = props;

  return (
    <Accordion.Item
      eventKey={eventKey}
      style={style}
      className={`bg-transparent border-0 ${styles.menuItem}`}
    >
      {isCollapsed && (
        <>
          <Accordion.Button
            as={link ? Link : Accordion.Button}
            to={link || ''}
            className={`p-2 justify-content-center ${styles.menuItemInner}`}
            bsPrefix="accordion-button empty-child"
            onClick={() => setShow && setShow(false)}
          >
            <div className="d-flex align-items-center p-2 bg-white rounded-2">
              <span className="lh-1 fs-5">{icon}</span>
            </div>
          </Accordion.Button>
          {!emptyChild && (
            <div className={`bg-gray-200 ${styles.menuSubItems}`}>
              <div className="p-2 ps-3">
                <div className="fw-bolder">{title}</div>
              </div>
              <Nav className={`flex-column ${styles.navItemCollapse}`}>
                {children}
              </Nav>
            </div>
          )}
        </>
      )}
      {!isCollapsed && (
        <>
          <Accordion.Button
            as={link ? Link : Accordion.Button}
            to={link || ''}
            className="p-3"
            bsPrefix={emptyChild ? 'accordion-button empty-child' : ''}
            onClick={() => setShow && setShow(false)}
          >
            <div className="d-flex align-items-center">
              <span className="lh-1 fs-5 me-2">{icon}</span>
              <span className="sidebar-text lh-1">{title}</span>
            </div>
          </Accordion.Button>
          <Accordion.Body className="multi-level bg-white">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </>
      )}
    </Accordion.Item>
  );
};

const NavItem = (props) => {
  const {
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    logoNav,
    setShow,
    style,
  } = props;
  const { pathname } = useLocation();
  const classNames = badgeText
    ? 'd-flex justify-content-start align-items-center justify-content-between'
    : '';
  let navItemClassName = link === pathname ? `active ${styles.active}` : '';

  if (link === '/dashboard' && pathname === '/') {
    navItemClassName = 'active';
  }
  if (logoNav) navItemClassName += 'logo-nav';

  const linkProps = external ? { href: link } : { as: Link, to: link };

  return (
    <Nav.Item
      className={`${navItemClassName} ${styles.navItem}`}
      style={style}
      onClick={() => setShow(false)}
    >
      <Nav.Link {...linkProps} target={target} className={classNames}>
        <span>
          {icon ? (
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />{' '}
            </span>
          ) : null}
          {image ? (
            <Image
              src={image}
              width={40}
              height={40}
              className="sidebar-icon svg-icon"
            />
          ) : null}

          <span className="sidebar-text">{title}</span>
        </span>
        {badgeText ? (
          <Badge
            pill
            bg="light"
            text="dark"
            className="badge-md notification-count ms-2"
          >
            {badgeText}
          </Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};

const Sidebar = (props) => {
  const { setIsCollapsed, isCollapsed = false } = props;

  const [show, setShow] = useState(false);
  const showClass = show ? 'show' : '';
  const onCollapse = () => setShow(!show);

  const { data: storeData } = useFetchStore();
  const { data: accountData, isLoading } = useFetchAccount();

  // const isShopIntegration = localStorage.getItem('pc-integration');
  const { pathname } = useLocation();
  // let defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';
  let path = pathname;
  if (pathname.indexOf('/product') === 0) path = '/products';
  if (pathname.indexOf('/project') === 0) path = '/orders';
  if (pathname.indexOf('/addSide/product') !== -1) path = '/products';
  if (pathname.indexOf('/admin-settings/') === 0) path = '/admin-settings';

  let defaultKey = '/dashboard';

  switch (path) {
    case '/':
    case '/dashboard':
      defaultKey = '/dashboard';
      break;
    case '/order':
    case '/orders':
    case '/designs':
    case '/quotation-requests':
    case '/addProject':
      defaultKey = '/orders';
      break;
    case '/products':
    case '/addProduct':
    case '/product':
    case '/import-product':
    case '/inventory':
    case '/pod-products':
    case '/side-templates':
      defaultKey = '/products';
      break;
    case '/templates':
    case '/addTemplate':
    case '/template':
    case '/cliparts':
    case '/images':
    case '/fonts':
      defaultKey = '/designer';
      break;
    case '/settings':
    case '/settings/contact':
    case '/settings/security':
    case '/settings/appearance':
    case '/payment':
      defaultKey = '/settings';
      break;
    case '/connect-store':
      defaultKey = '/connect-store';
      break;

    case '/helpdesk/services':
    case '/helpdesk/tutorials':
    case '/helpdesk/workchat':
    case '/helpdesk':
    case '/helpdesk/projects':
    case '/helpdesk/projects/tickets':
    case '/helpdesk/projects/milestones':
      defaultKey = '/helpdesks';
      break;
    case '/integration':
    case '/integration/wix':
    case '/integration/shopify':
    case '/integration/wordpress':
    case '/integration/api':
      defaultKey = '/integration';
      break;
    case '/admin-settings':
    case '/admin-settings/side-templates':
      defaultKey = '/admin-settings';
      break;
    default:
      defaultKey = '/dashboard';
      break;
  }

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-dark px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Dashboard.path}>
          <Image
            src={Logo}
            className="navbar-brand-light"
            style={{ width: 32, height: 32 }}
          />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <div
          className={`collapse sidebar d-md-block bg-gray-100 border-end border-gray-200 overflow-visible ${showClass}`}
          style={{ maxWidth: isCollapsed ? '68px' : '' }}
        >
          <div className="sidebar-inner overflow-visible">
            <Nav className="flex-column">
              <div className="d-flex justify-content-between w-100 border-bottom border-gray-200">
                <a
                  href="https://www.printcart.com"
                  className="d-flex align-items-center p-3"
                  style={{ height: 53 }}
                >
                  <Image src={Logo} style={{ width: 32, height: 32 }} />
                  {!isCollapsed && (
                    <h5
                      className="text-dark fw-bold d-flex ms-2 ps-2 mb-0 align-items-center"
                      style={{ height: 32 }}
                    >
                      Printcart
                    </h5>
                  )}
                </a>
                <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                  <FontAwesomeIcon icon={faTimes} />
                </Nav.Link>
              </div>
              <Accordion
                as={Nav.Item}
                defaultActiveKey={defaultKey}
                style={{
                  maxHeight: 'calc(100vh - 107px)',
                  overflowY: isCollapsed ? 'unset' : 'scroll',
                }}
              >
                <CollapsableNavItem
                  isCollapsed={isCollapsed}
                  withCollapsed="true"
                  title="Dashboard"
                  eventKey="/"
                  icon={<MdOutlineSpaceDashboard />}
                  link={Routes.Dashboard.path}
                  setShow={setShow}
                  emptyChild
                />
                <CollapsableNavItem
                  isCollapsed={isCollapsed}
                  title="Orders"
                  icon={<BsFiles />}
                  eventKey="/orders"
                >
                  <NavItem
                    title="All Orders"
                    link={Routes.Orders.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Quotation Requests"
                    link={Routes.QuotationRequests.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Design Files"
                    link={Routes.Designs.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
                <CollapsableNavItem
                  isCollapsed={isCollapsed}
                  title="Products"
                  icon={<BiBox />}
                  eventKey="/products"
                >
                  <NavItem
                    title="Product Setting"
                    link={Routes.Products.path}
                    setShow={setShow}
                  />
                  {/* <NavItem
                    title="POD products"
                    link={Routes.PodProducts.path}
                    setShow={setShow}
                  /> */}
                  <NavItem
                    title="Side templates"
                    link={Routes.SideTemplates.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Setup Wizard"
                    link={Routes.StepWizard.path}
                    setShow={setShow}
                    target="_blank"
                    badgeText={<IoOpenOutline />}
                  />
                  {storeData && storeData.integration_type && (
                    <NavItem
                      title="Inventory Import"
                      link={Routes.Inventory.path}
                      setShow={setShow}
                    />
                  )}
                </CollapsableNavItem>
                <CollapsableNavItem
                  isCollapsed={isCollapsed}
                  title="Designer"
                  icon={<IoImageOutline />}
                  eventKey="/designer"
                >
                  <NavItem
                    title="Cliparts"
                    link={Routes.Cliparts.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Fonts"
                    link={Routes.Fonts.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Images"
                    link={Routes.Images.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Templates"
                    link={Routes.Templates.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
                <CollapsableNavItem
                  isCollapsed={isCollapsed}
                  title="Settings"
                  eventKey="/settings"
                  icon={<BsGear />}
                  setShow={setShow}
                >
                  <NavItem
                    title="Account"
                    link={Routes.Settings.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Contact"
                    link={Routes.SettingsContact.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Security"
                    link={Routes.SettingsSecurity.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Appearance"
                    link={Routes.SettingsAppearance.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Payment"
                    link={Routes.Payment.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
                <CollapsableNavItem
                  isCollapsed={isCollapsed}
                  title="HelpDesk"
                  icon={<AiOutlineQuestionCircle />}
                  eventKey="/helpdesks"
                >
                  <NavItem
                    title="Know-How"
                    link={Routes.HelpDesk.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Premium Services"
                    link={Routes.HelpDeskService.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Project Development"
                    link={Routes.Project.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Support Ticket"
                    link={Routes.ProjectTicket.path}
                    setShow={setShow}
                  />
                  {/* <NavItem
                    title="Project Milestones"
                    link={Routes.ProjectMilestone.path}
                    setShow={setShow}
                    eventKey="projects/milestones"
                  /> */}
                </CollapsableNavItem>
                <CollapsableNavItem
                  isCollapsed={isCollapsed}
                  title="Integration"
                  icon={<CgFileDocument />}
                  eventKey="/integration"
                >
                  <NavItem
                    title="Integration Connect"
                    link={Routes.Integration.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Wix"
                    link={Routes.IntegrationWix.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Shopify"
                    link={Routes.IntegrationShopify.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="Wordpress"
                    link={Routes.IntegrationWordpress.path}
                    setShow={setShow}
                  />
                  <NavItem
                    title="API"
                    link={Routes.IntegrationApi.path}
                    setShow={setShow}
                  />
                </CollapsableNavItem>
                {!isLoading && !!accountData?.is_admin && (
                  <CollapsableNavItem
                    isCollapsed={isCollapsed}
                    title="Admin Settings"
                    icon={<MdOutlineAdminPanelSettings />}
                    eventKey="/admin-settings"
                  >
                    <NavItem
                      title="Settings"
                      link={Routes.AdminSettings.path}
                      setShow={setShow}
                    />
                    <NavItem
                      title="Side Template"
                      link={Routes.AdminSideTemplates.path}
                      setShow={setShow}
                    />
                  </CollapsableNavItem>
                )}
              </Accordion>
            </Nav>
          </div>
          <div
            className={`d-flex align-items-center justify-content-${
              isCollapsed ? 'center' : 'between'
            } py-3  position-absolute bottom-0 w-100 ${
              isCollapsed ? 'px-2' : 'px-3'
            }`}
            style={{ backgroundColor: 'inherit' }}
          >
            {!isCollapsed && (
              <Form.Label
                htmlFor="collapse-menu-sidebar"
                className="ms-2 mb-0 fw-medium text-nowrap d-flex align-items-center"
              >
                <HiChevronDoubleLeft />
                Collapse menu
              </Form.Label>
            )}
            <Form.Check
              id="collapse-menu-sidebar"
              className={isCollapsed ? 'text-center' : 'text-right'}
              type="switch"
              checked={!isCollapsed}
              onChange={() => {
                setIsCollapsed(!isCollapsed);
              }}
            />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
