export const Routes = {
  Dashboard: { path: '/' },
  Orders: { path: '/orders' },
  AddProject: { path: '/addProject' },
  EditOrder: { path: '/order/:id' },
  AddDesign: { path: '/addDesign' },
  EditDesign: { path: '/design/:id' },
  Designs: { path: '/designs' },
  Products: { path: '/products' },
  PodProducts: { path: '/pod-products' },
  AddProduct: { path: '/addProduct' },
  EditProduct: { path: '/product/:id' },
  PodProductPage: { path: '/pod-product/:id' },
  ProductPrintingOption: { path: '/product/:id/printing-option' },
  Templates: { path: '/templates' },
  AddTemplate: { path: '/addTemplate' },
  CreateTemplate: { path: '/createTemplate' },
  EditTemplate: { path: '/template/:id' },
  EditSide: { path: '/side/:id' },
  EditSideTemplate: { path: '/side-template/:id' },
  SideTemplates: { path: '/side-templates' },
  AddSide: { path: '/addSide/product/:productId' },
  Cliparts: { path: '/cliparts' },
  Fonts: { path: '/fonts' },
  Images: { path: '/images' },
  Clipart: { path: '/clipart/:id' },
  Signin: { path: '/signin' },
  Signup: { path: '/signup' },
  ResetPassword: { path: '/password-reset/:token' },
  ForgotPassword: { path: '/password-reset' },
  EmailVerify: { path: '/email/verify/:token' },
  Settings: { path: '/settings' },
  SettingsContact: { path: '/settings/contact' },
  SettingsSecurity: { path: '/settings/security' },
  SettingsAppearance: { path: '/settings/appearance' },
  NotFound: { path: '*' },
  ServerError: { path: '/500' },
  Welcome: { path: '/welcome' },
  StepWizard: { path: '/setup-wizard' },
  StepWizardAccessible: { path: '/setup-wizard-accessible' },
  WizardCreateProduct: { path: '/wizard/create-product' },
  WizardCreateSide: { path: '/wizard/create-side' },
  WizardGetEmbed: { path: '/wizard/get-embeded-code' },
  WizardAllSet: { path: '/wizard/all-set' },
  ImportProduct: { path: '/import-product' },
  Inventory: { path: '/inventory' },
  StoreList: { path: '/stores' },
  Payment: { path: '/payment' },
  Subscription: { path: '/subscription' },
  SocialLoginCallback: { path: '/sociallogincallback' },
  AuthCallBack: { path: '/auth-callback' },
  Authorize: { path: '/authorize' },
  StepIntegration: { path: '/step-integration' },
  IntegrationAuth: { path: '/integration-auth' },
  Side2: { path: '/side2/:id' },
  AddSide2: { path: '/addSide2/product/:productId' },
  HelpDesk: { path: '/helpdesk' },
  HelpDeskService: { path: '/helpdesk/services' },
  HelpDeskTutorial: { path: '/helpdesk/tutorials' },
  HelpDeskWorkchat: { path: '/helpdesk/workchat' },
  Project: { path: '/helpdesk/projects' },
  ProjectTicket: { path: '/helpdesk/projects/tickets' },
  ProjectMilestone: { path: '/helpdesk/projects/milestones' },
  Integration: { path: '/integration' },
  IntegrationShopify: { path: '/integration/shopify' },
  IntegrationWix: { path: '/integration/wix' },
  IntegrationWordpress: { path: '/integration/wordpress' },
  IntegrationApi: { path: '/integration/api' },
  AdminSettings: { path: '/admin-settings' },
  AdminSideTemplates: { path: '/admin-settings/side-templates' },
  QuotationRequests: { path: '/quotation-requests' },
};
